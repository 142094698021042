// src/TimezoneConverter.js

import React, { useState } from 'react';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';

const timezones = moment.tz.names().map(tz => ({ value: tz, label: tz }));

const TimezoneConverter = () => {
  const [leftTimezone, setLeftTimezone] = useState('');
  const [rightTimezone, setRightTimezone] = useState('');
  const [leftDate, setLeftDate] = useState(new Date());

  const handleLeftDateChange = (date) => {
    setLeftDate(date);
  };

  const convertTime = () => {
    if (leftTimezone && rightTimezone) {
      const rightDate = moment.tz(leftDate, leftTimezone).tz(rightTimezone);
      return rightDate.format('MMMM Do YYYY, h:mm:ss a');
    }
    return '';
  };

  return (
    <div>
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <div style={{ marginRight: '20px' }}>
          <Select
            options={timezones}
            onChange={(selectedOption) => setLeftTimezone(selectedOption.value)}
            placeholder="Select left timezone"
          />
          <DatePicker
            selected={leftDate}
            onChange={handleLeftDateChange}
            showTimeSelect
            dateFormat="Pp"
            popperPlacement="bottom-start"
            popperModifiers={[
              {
                name: 'preventOverflow',
                options: {
                  rootBoundary: 'viewport',
                  padding: 8,
                },
              },
              {
                name: 'offset',
                options: {
                  offset: [0, 10],
                },
              },
            ]}
          />
        </div>
        <div>
          <Select
            options={timezones}
            onChange={(selectedOption) => setRightTimezone(selectedOption.value)}
            placeholder="Select right timezone"
          />
          <div>
            <p>{convertTime()}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimezoneConverter;
