// src/App.js

import React from 'react';
import TimezoneConverter from './TimezoneConverter';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Timezone Converter</h1>
        <TimezoneConverter />
      </header>
    </div>
  );
}

export default App;
